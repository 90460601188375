<template>
  <div class="overlay">
    <div id="container-txt">

      <h1 class="uppercase">La solution pour <br>la transition <br> énergétique</h1>
      <h3 class="italicLight">par et pour les collectivités locales.</h3>
      <div id="boutonFleche">
        <!-- <router-link to="realisation">
          <button class="button btn-nos-realisations">Nos réalisations</button>
        </router-link> -->
        <div class="fleche">
          <div class="fleche1"></div>
          <div class="fleche2"></div>
        </div>
      </div>

    </div>

    <img id="panneaux" src="../../assets/img/panneauxs.webp" alt="logo Sictom" width="170px">

  </div>
</template>

<script>
export default {
  name: 'PrincipalView'
}
</script>

<style scoped>
@media screen and (min-width:641px) {
  .overlay {
    display: grid;
    position: relative;
    width: 100%;
    height: 77vh;
    text-align: left;
    color: #16253F;
  }

  img#panneaux {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 65%;
    z-index: -1;
    object-fit: cover;
    grid-area: 1 / 1 / 2 / 2;
  }

  #container-txt {
    position: relative;
    margin-left: 7%;
    top: 7.5vh;
    grid-area: 1 / 1 / 2 / 2;
  }

  h1 {
    font-size: 4.8rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  h3 {
    margin-top: 0;
    font-size: 2.5rem;
    font-weight: normal;
  }

  .btn-nos-realisations {
    font-size: 1.56rem;
  }

  #boutonFleche {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
  }

  .fleche {
    position: relative;
    right: 1rem;
  }

  .fleche1 {
    position: relative;
    left: calc(1.59rem - 0.125rem);
    top: 2rem;

    height: 3.75rem;
    width: 0.25rem;

    background-color: #010B48;
  }

  .fleche2 {
    color: #010B48;
    width: 2rem;
    height: 2rem;
    border-width: 0 0.25rem 0.25rem 0;
    border-style: solid;
    transform: rotate(45deg);
    transform-origin: bottom left;
  }

  .imgPanneaux {
    position: absolute;
    top: 155px;
    left: 654px;
    z-index: -1;
  }
}


@media screen and (max-width:640px) {
  h1 {
    margin: 0;
    font-size: 30px;
    line-height: 45px;
    padding-left: 10%;
    position: relative;
    top: -8%
  }

  .overlay {
    margin-top: 25px;
    position: relative;
    width: 100%;
    height: 308px;
    text-align: left;
    color: #16253F;
    background-image: url("../../assets/img/panneauxs.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 15%;
  }

  img#panneaux {
    display: none;
  }

  #container-txt {
    position: relative;
    top: -25px;
  }

  h3 {
    margin-top: 0;
    font-size: 25px;
    padding-left: 10%;
    position: relative;
    top: -8%;
    font-weight: normal;
  }

  .btn-nos-realisations {
    display: none;
  }

  #boutonFleche {
    display: none;
  }

  .fleche1 {
    vertical-align: middle;
    margin: auto;
    margin-top: 100px;
    color: #010B48;
    box-sizing: border-box;
    width: 20px;
    height: 60px;
    border-width: 0 0 0 4px;
    border-style: solid;

  }

  .fleche2 {
    position: relative;
    left: 117px;
    bottom: 23px;
    vertical-align: middle;
    color: #010B48;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 10px;
    transform: rotate(135deg);
  }

  .imgPanneaux {
    position: absolute;
    top: 155px;
    left: 654px;
    z-index: -1;
  }

}
</style>