<template>
  <div class="bigContainer">
    <h2 class="uppercase">
      <span class="italicLight">Nos </span>objectifs
    </h2>

    <p class="smallContainer">
      Les objectifs de la SEMPER sont l'appropriation de la transition
      énergétique<br />
      <span class="semiBold">par les collectivités locales et la production d'énergie renouvelable
        pour favoriser <br />
        les retombées économiques sur le territoire.</span>
    </p>
    <router-link  to="society">
      <button class="button" >En savoir plus</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ObjectifsView",
};
</script>

<style scoped>
@media screen and (min-width: 641px) {
  .bigContainer {
    padding-top: 16vh;
    padding-bottom: 8.5vh;
  }

  h2 {
    font-size: 3.125rem;
    margin-bottom: 0;
  }

  .smallContainer {
    margin-top: 2.5vh;
    font-size: 2.1875rem;
    line-height: 3.75rem;
  }

  .button {
    margin-top: 3vh;
  }
}

@media screen and (max-width: 640px) {
  .bigContainer {
    padding: 8% 12% 10% 12%;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 5%;
  }

  .smallContainer {
    margin-top: 0.5%;
    font-size: 18px;
    line-height: 32px;
  }

  .button {
    width: 70%;
    height: 2.5em;
    font-size: 15px;
  }
}
</style>
