<template>
  <div id="app">
    <TopView/>

    <div v-for="(r, index) in realisations" :key="index" >
      <RealisationParaViewLeft v-if="(index % 2 == 1)" :id="index" :index="index" :logo="r.logo" :title="r.title" :subtitle="r.subtitle" :tonnesCO2="r.tonnesCO2"
      :implementation="r.implementation" :power="r.power" :productible="r.productible" :surface="r.surface"
      :investment="r.investment" :production="r.production" :conso="r.conso" :partners="r.partners" />
      
      <RealisationParaViewRight v-if="(index % 2 == 0)" :id="index" :index="index" :first="index == 0" :last="index == (realisations.length - 1)" :logo="r.logo" :title="r.title" :subtitle="r.subtitle" :tonnesCO2="r.tonnesCO2"
      :implementation="r.implementation" :power="r.power" :productible="r.productible" :surface="r.surface"
      :investment="r.investment" :production="r.production" :conso="r.conso" :partners="r.partners" />
    </div>
    <div id="end"></div>

    <FooterView />
</div>
</template>

<script>
import TopView from '../components/Top.vue'
import FooterView from '../components/Footer.vue'
import RealisationParaViewRight from '../components/Realisation/RealisationParaRight.vue'
import RealisationParaViewLeft from '../components/Realisation/RealisationParaLeft.vue'
import realisationsJson from '../assets/data/realisation.json'

export default {
  name: 'RealisationPage',
  components: {
    TopView,
    FooterView,
    RealisationParaViewRight,
    RealisationParaViewLeft
  },
  data() {
    return {
      realisations: realisationsJson['realisations']
    }
  }
  
}
</script>

