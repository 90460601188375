<template>
  <div id="container">

    <TransitionGroup name="list">
      <img
        v-for="(img, indexFor) in realisations"
        :key="img.logo"
        v-show="index == indexFor"
        class="background-image"
        :src="require('@/assets/img/' + img.logo )"
        alt="Image de panneaux solaires"
        :style="'z-index: -' + indexFor" />
    </TransitionGroup>

    <div id="overlay">
      <div id="cross" @click='routeTo("realisation", "#" + index)'>
        <div class="baton baton-top"></div>
        <div class="baton baton-bottom"></div>
      </div>

      <div id="titles">
        <h2>
          {{ realisations[index].title }}
        </h2>
        <h3>
          {{ realisations[index].subtitle }}
        </h3>
        <span>
          {{ realisations[index].tonnesCO2 }} tonnes de CO2 évité
        </span>
      </div>

      <div id="fleches">
        <div class="background-fleches background-fleches-gauche">
          <div @click="previous" class="fleche fleche-gauche"></div>
        </div>
        <div class="background-fleches background-fleches-droite">
          <div @click="next" class="fleche fleche-droite"></div>
        </div>

      </div>

      <div id="footer">
        <!-- <div id="logos">
          <img src="" alt="Logo 1">
          <img src="" alt="Logo 2">
          <img src="" alt="Logo 3">
        </div> -->

        <div id="informations">
          <!-- <li><span>Productible : </span><span>{{ realisations[index].productible }}</span></li> -->
          <span v-if="realisations[index].surface"><span>Surface : </span><span class="regular">{{ realisations[index].surface }}</span> | </span>
          <span v-if="realisations[index].investment"><span>Montant de l'invertissement : </span><span class="regular">{{  realisations[index].investment }}</span> | </span>
          <span>Production : </span><span class="regular">{{  realisations[index].production }}</span> |
          <span>Equivalent consommateur électrique : </span><span class="regular">{{  realisations[index].conso }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import realisationsJson from '@/assets/data/realisation.json';

export default {
  name: "CarouselComponent",
  props: ['startIndex'],
  data() {
    return {
      realisations: realisationsJson['realisations'], 
      index: this.startIndex
    }
  },
  methods: {
    routeTo(route, hash) {
      this.$router.push({ path: route, hash: hash })
    },
    next() {
      this.index = this.calcNext(this.index, this.realisations.length);
    },
    previous() {
      this.index = this.calcPrevious(this.index, this.realisations.length);
    },
    calcNext(index, max) {
      return (index + 1) % max;
    },
    calcPrevious(index, max) {
      return index <= 0 ? max - 1 : index - 1;
    }
  }
}
</script>

<style scoped>
#container {
  position: relative;
  height: 100%;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -2;
}

.first-z-index {
  z-index: -1 !important;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.7s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}

#overlay {
  position: relative;
  height: 100%;
  width: 100%;
}

#cross {
  position: absolute;
  top: 6vh;
  right: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}

.baton {
  width: 100%;
  height: 4px;
  background-color: white;
  border-radius: 2px;
}

.baton-top {
  transform: translateY(50%) rotate(-45deg);
  transform-origin: center center;
}

.baton-bottom {
  transform: translateY(-50%) rotate(45deg);;
  transform-origin: center center;
}

#titles {
  position: absolute;
  top: 5.5vh;
  left: 8vw;

  max-width: 80%;

  color: white;
  text-align: left;
}

#titles>h2 {
  font-size: 2.5rem;
  line-height: 2.5rem;

}

#titles>h3 {
  font-size: 2.5rem;
  line-height: 3.3rem;

  font-family: 'Open Sans Light Italic';
  font-weight: 100;
  margin: 0;
}

#titles>span {
  color: var(--font-blue);
  background-color: white;
  padding: 2px 7px;
  line-height: 2.8rem;
  font-size: 1.25rem;
}

#fleches {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  width: 100%;
}

.fleche {
  border-color: white !important;
  cursor: pointer;
}

.fleche-gauche {
  border-top: solid 3px;
  border-left: solid 3px;
 
  transform: translateY(50%) rotate(-45deg);
  transform-origin: top left;
}

.background-fleches {
  position: absolute;
  display: inline;

  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;

}


.background-fleches-droite {
  right: 3vw;
}

.background-fleches-gauche {
  left: 3vw;
}

.fleche-droite {
  right: 3vw;

  transform: translateY(50%) rotate(45deg);
  transform-origin: top right;

  border-top: solid 3px;
  border-right: solid 3px;
}

#footer {
  position: absolute;
  bottom: 20px;

  display: flex;
  flex-direction: column;
  width: 100%;
}

#logos {
  display: flex;
  justify-content: right;
  align-items: center;
}

#informations {
  text-align: center;
  align-items: center;
  justify-content: center;

  background-color:rgba(255, 255, 255, 0.7);
  padding: 0.7rem 4vw;

  font-size: 1rem;
}

@media screen and (min-width: 640px) {
  #cross {
    width: 35px;
    height: 35px;
    padding: 12px;
  }

  .fleche {
    height: 23px;
    width: 23px;
}

.background-fleches {

  padding: 18px;
}


}

@media screen and (max-width: 640px) {
  #cross {
    width: 20px;
    height: 20px;
    padding: 8px;

  }

  .fleche {
    height: 15px;
    width: 15px;
}

.background-fleches {

padding: 13px;
}
}


</style>