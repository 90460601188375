<template>
  <div class="containerSocietyPara">

    <div class="underneathImg">
      <img :src="require('../../assets/img/' + logo)" alt="logo société" width="80px">
    </div>

    <div class="headband white uppercase">
      {{ title }}
    </div>
    
    <div class="para regular" v-html="para">
    </div>

  </div>
</template>

<script>
export default {
  name: 'SocietyParaView',
  props: {
    logo: String,
    title: String,
    para: String
  }
}
</script>

<style scoped>
@media screen and (min-width: 641px) {

  * {
    --padding-left: 2.6vw;
  }

  .containerSocietyPara {
    background-color: white;
    border-radius: 0.4rem;
    margin-bottom: 15%;
  }

  .underneathImg {
    height: 4rem;
    text-align: left;
  }

  .headband {
    background-color: #050734;
    padding-left: var(--padding-left);
    font-size: 1.5625rem;
    line-height: 2.2rem;
    text-align: left;
  }

  .para {
    font-size: 1rem;
    line-height: 1.8rem;
    text-align: left;
    padding: 2% 5% 5% var(--padding-left);
  }

  img {
    min-height: 5rem;
    max-height: 5rem;
    max-width: 14rem;

    width: 100%;
    height: 100%;
    
    object-fit: contain;
    object-position: left;
    text-align: left;

    position: relative;
    transform: translateY(-50%);
    margin-left: var(--padding-left);

  }
}

@media screen and (max-width: 640px) {
  .containerSocietyPara {
    background-color: white;
    margin-bottom: 15%;
  }

  .underneathImg {
    margin: 5%;
  }
  .underneathImg > img {
    height: auto;
    width: auto;
    max-height: 70px;
    max-width: 250px;
    object-fit: contain;
  }


  .headband {
    background-color: #050734;
    font-size: 20px;
    line-height: 32px;
    padding: 2% 0 2% 0;
  }

  .para {
    margin: 5% 10% 0 10%;
    font-size: 16px;
    line-height: 30px;
    line-height: 2em;
    text-align: left;
  }

}
</style>