<template>
  <div class="container-right realisation-txt" :class="{'theme-color': first}">
    <span>
      <div class="fleche" :class="{'theme-fleche':first}"></div>
    </span>
    <div id="container-interne-right theme">
      <h3 class="uppercase">{{ title }}</h3>
      <span id="subtitle" v-if="subtitle" class="italicLight uppercase">
        {{ subtitle }}
      </span>
      <br>
      <span class="mis-en-service" :class="{'theme-subtitle': first}">
        Mise en service : <span class="regular">{{ implementation }}</span>
      </span>

      <div id="project-characeristics">
        <div v-if="power">Puissance du projet : <span class="regular">{{ power }}</span></div>
        <div v-if="productible">Productible : <span class="regular">{{ productible }}</span></div>
        <div v-if="surface">Surface du projet : <span class="regular">{{ surface }}</span></div>
        <div v-if="investment">Montant de l'investissement : <span class="regular">{{ investment }}</span></div>
        <div v-if="production">Production annuelle : <span class="regular">{{ production }}</span></div>
        <div v-if="conso">Equivalent consommation électrique annuelle : <span class="regular">{{ conso }}</span></div>
        <div>Partenaires : <span class="regular">{{ partners }}</span></div>
      </div>
    </div>
</div>
</template>

<script>

export default {
  props: {
    first: Boolean,
    title: String,
    subtitle: String,
    implementation: String,
    power: String,
    productible: String,
    surface: String,
    investment: String,
    production: String,
    conso: String,
    partners: String,
  },
};
</script>

<style scoped>

.container-right {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-self: left;
  max-width: 85%;
}

#container-text-fleche {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.fleche {
  height: 1.3rem;
  width: 1.3rem;
  border-right: 4px solid #050734;
  border-bottom: 4px solid #050734;
  transform-origin: right bottom;
  transform: rotate(-45deg);
}

h3 {
  font-size: 2.5rem;
  line-height: 3.125rem;
  margin: 0;
}

#subtitle {
  font-size: 2.5rem;
  line-height: 3.125rem;
  margin: 0;
}

.mis-en-service {
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: #050734;
  color: white;
  padding: 1px 5px;
  display: inline-block;
  margin-top: 0.2rem;
}

#project-characeristics {
  margin-top: 3.8vh;
  font-size: 1rem;
}

#project-characeristics > div {
  margin-top: 1.3rem;
}

/* Theme actifs seulement pour la top view */
.theme-color {
  color: white;
}

.theme-fleche {
  border-color: white;
}

.theme-subtitle {
  background-color: white;
  color: #050734;
}

@media screen and (max-width: 640px) {
  #project-characeristics {
    font-size: 16px;
  }
}

</style>