<template>
  <div class="containerFooter">
    <div class="leftFooter">
      <img id="logoSemper" src="../assets/img/logoSemperTransparent.webp" alt="logo Semper">
      <p id="maitrise">La maitrise de la transition <br> énergétique par les collectivités locales.</p>
      <p id="copyright">©2023 Semper All Rights Reserved | <span><router-link to="mentions-legales" style="color: white; text-decoration: none;">Mentions Légales</router-link></span></p>
    </div>
    <div class="contact regular">
      <h4 class="uppercase ">Contact</h4>
      <ul>
        <li><img src="../assets/img/footer/phone.svg" alt="">06 68 90 24 84</li>
        <li><img src="../assets/img/footer/envelope.svg" alt=""><a style="color: white; text-decoration: none;" href="mailto: s.martinez@sempersud.fr">S.MARTINEZ@sempersud.fr</a></li>
        <li><img src="../assets/img/footer/location.svg" alt="">BP 137 34 120 PEZENAS</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterView'
}
</script>

<style scoped>
@media screen and (min-width: 641px) {
  .containerFooter {
    padding: 6.3vh 5% 6.3vh 7.1%;
    display: flex;
    justify-content: space-between;
    background-color: #010B48;
    color: white;
  }

  .leftFooter {
    width: 40%;
    text-align: left;
    font-size: 1.5625rem;
  }

  #maitrise {
    font-size: 1.4375rem;
  }

  #copyright {
    font-size: 0.9rem;
  }

  #copyright > span {
    font-family: 'Open Sans Regular';
  }

  .contact {
    text-align: left;
  }
  h4 {
    font-size: 1.5625rem;
    margin-top: 0;
  }

  .contact ul {
    font-family: 'Open Sans Regular';
    line-height: 2rem;
    list-style: none;
    padding-left: 0;
  }

  .contact img {
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 10px;
  }

  #logoSemper {
    height: 2.5625rem;
  }

}

@media screen and (max-width: 640px) {
  .containerFooter {
    padding: 4% 5% 4% 5%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    background-color: #010B48;
    color: white;
  }

  .leftFooter {
    padding-top: 10%;
    font-size: 12px;
  }

  .contact>h4 {
    text-align: center;
    font-size: 15px;
  }

  .contact {
    text-align: left;
    font-size: 12px;
  }

  .contact img {
    height: 1.2rem;
    width: 1.2rem;
    margin-right: 10px;
  }

  img {
    width: 40%;
  }
}
</style>