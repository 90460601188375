<template>
  <div id="app">
    <Top/>
    <Title/>
    <Objectifs/>
    <Actionnaires/>
    <Installations/>
    <Footer/>
  </div>
</template>

<script>
import Top from '../components/Top.vue'
import Title from '../components/HomePage/Title.vue'
import Objectifs from '../components/HomePage/Objectifs.vue'
import Actionnaires from '../components/HomePage/Actionnaires.vue'
import Installations from '../components/HomePage/Installations.vue'
import Footer from '../components/Footer.vue'

export default {
  name: 'HomePage',
  components: {
    Top,
    Title,
    Objectifs,
    Actionnaires,
    Installations,
    Footer
  }
}
</script>

<style>

</style>
