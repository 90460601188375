<template>
  <div id="container-page">
    <Top id="top"/>
    <Carousel :startIndex="this.$route.query.index"/>
  </div>
</template>

<script>
import Top from '../components/Top.vue'
import Carousel from '../components/Carousel/Carousel.vue'

export default {
  name: 'HomePage',
  components: {
    Top,
    Carousel,
  }
}
</script>

<style scoped>
#container-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media (max-width: 640px) {
  #top {
    display: none;
  }
}
</style>