<template>
  <div class="container-actionnaires lightBlue">
    <h2 class="uppercase"><span class="italicLight">Nos </span> actionnaires</h2>
    <p class="paraActionnaires">
      <span class="regular">Son capital social est détenu majoritairement par le public avec le <span style="font-family: 'Open Sans Bold'">SICTOM </span>
      </span>Pézenas Agde (38,32%),<br>
      <span class="regular">La Communauté d'Agglomération </span>Hérault Méditerranée (5,66%) <span class="regular">et
      </span>Hérault Energies (8,97%).<br>
      <span class="regular">Les actionnaires privés sont </span>TotalEnergies (34,14%), Qair (7,90%)<span class="regular">
        et </span>BASALT'ENR (5%)
    </p>
    <div class="logoContainer">
      <img src="../../assets/img/logoSictom.webp" alt="logo Sictom" width="100px">
      <img src="../../assets/img/logoAgglo.webp" alt="logo Sictom" width="140px">
      <img src="../../assets/img/logoHerault.webp" alt="logo Sictom" width="70px">
      <img src="../../assets/img/logoTotal.webp" alt="logo Sictom" width="90px">
      <img src="../../assets/img/qair.webp" alt="logo Sictom" width="100px">
      <img src="../../assets/img/logoBasaltenr.webp" alt="logo Sictom" width="170px">
    </div>

  </div>
</template>

<script>
export default {
  name: 'ActionnairesView'
}
</script>

<style>
@media screen and (min-width: 641px) {
  .container-actionnaires {
    padding-top: 7.7vh;
    padding-bottom: 8.8vh;
  }

  h2 {
    font-size: 3.125rem;
    margin-bottom: 0;
    margin-top: 0;
  }

  .logoContainer {
    display: flex;

    justify-content: center;
    align-items: center;
    margin: 0 12%;
    flex-wrap: wrap;

    gap: 5vw;

    align-items: center;
  }

  .logoContainer img {
    object-fit: contain;
    max-height: 6.5rem;
    max-width: 150px;
  }

  .paraActionnaires {
    font-size: 1.5625rem;
    line-height: 2em;
    margin-bottom: 2.5%;
  }
}

@media screen and (max-width: 640px) {
  .container-actionnaires {
    padding: 38px 12% 10% 12%;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .logoContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 15px;
    margin-top: 45px;
    justify-items: center;
    align-items: center;
  }

  .logoContainer>img {
    height: 45px;
    max-width: 60px;
    object-fit: contain;
  }

  .paraActionnaires {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 2.5%;
  }

}
</style>


