<template>
  <div class="container">


      <div id="container-left">
        <div id="container-header-img">
          <span class="nbTonnes"> {{ tonnesCO2 }} tonnes </span><br />
          <span class="textBackgroundWhite">de rejets de CO2 évité</span>
        </div>
        <img v-on:click="routeTo" :src="require('../../assets/img/' + logo)" alt="Image de panneaux solaires" />
      </div>

      <realisation-txt :title="title" :subtitle="subtitle" :implementation="implementation" :power="power"
        :productible="productible" :surface="surface" :investment="investment" :production="production" :conso="conso"
        :partners="partners">
      </realisation-txt>


  </div>
</template>

<script>

import RealisationTxt from './RealisationText.vue'

export default {
  name: "RealisationParaViewLeft",
  props: {
    index: Number, 
    logo: String,
    title: String,
    subtitle: String,
    tonnesCO2: String,
    implementation: String,
    power: String,
    productible: String,
    surface: String,
    investment: String,
    production: String,
    conso: String,
    partners: String
  },
  components: {
    RealisationTxt
  },
  methods: {
    routeTo() {
      this.$router.push({ path: '/carousel', query: { index: this.index } })
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
  z-index: 1;

  display: grid;

  background-color: #DEE9EF;
  text-align: left;
}

/* Coté gauche */
#container-left {
  position: relative;
  height: inherit;
}

#container-header-img {
  z-index: 2;
}

.textBackgroundWhite {
  background-color: #050734;
  color: white;
  margin: 10px 0 5px 0;
  padding: 0 5px;
  width: 38%;
  font-size: 1.5rem;
}

img {
  cursor: pointer;
}

@media screen and (min-width: 640px) {
  .container {
    grid-template-columns: 39% auto;
    column-gap: 7.4vw;
    padding-top: 25.6vh;
    padding-bottom: 9.8vh;
  }

  #container-header-img {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 12%;

    transform: translateY(calc(-50% - 25.6vh + 10.7vh));
  }

  img {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    object-fit: cover;
    height: calc(100% + 25.6vh - 10.7vh);
  }
}


@media screen and (max-width: 640px) {
  .container {
    background-color: #DEE9EF;
    position: relative;
    text-align: left;
    grid-template-rows: auto auto;
  }

  .container > * {
    position: relative;
    top: -80px;
  }

  /* Cote droit */

  #container-text-fleche {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  /* Coté gauche */
  #container-left {
    position: relative;
    padding-right: 35px;
  }

  #container-header-img {
    position: absolute;
    z-index: 2;
    top: 10px;
    left: 15px;
  }

  #container-left img {
    width: 100%;
    height: 40.6vh;
    object-fit: cover;
  }

  #nbTonnes {
    font-size: 6.25rem;
    line-height: 6.25rem;

    color: transparent;
    -webkit-text-stroke: 2px #050734;
  }

  .realisation-txt {
    margin: 40px auto 40px 35px;
  }
}
</style>