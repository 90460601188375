import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SocietyView from '../views/SocietyView.vue'
import RealisationView from '../views/RealisationView.vue'
import CarouselView from '../views/CarouselView.vue'
import MentionLegales from '../views/MentionLegales.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/society',
    name: 'society',
    component: SocietyView
  },
  {
    path: '/realisation',
    name: 'realisation',
    component: RealisationView
  },
  {
    path: '/carousel',
    name: 'carousel',
    component: CarouselView
  },
  {
    path: '/mentions-legales',
    name: 'MentionLegales',
    component: MentionLegales
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior (to) {
    if (to.hash) {
       return { selector: to.hash }
    }
}
})

export default router
