<template>
  <div class="imgAndTextContainer">

    <div class="containerFirstPara">
      <div class="firstPara">
        La SEMPER est une société anonyme d’économie mixte (SEM) qui se caractérise par sa nature
        d’entreprise commerciale, son capital public majoritaire et sa vocation à satisfaire l’intérêt
        général. <br>
      </div>
      <div class="smallTextFirstPara regular">
        Nous assurons le financement des projets au travers de sociétés de projets au sein desquelles la
        SEMPER prend des participations. <br>
        La SEMPER a pour vocation à contribuer à l’émergence de centrales de production d’énergie verte à
        partir des ressources naturelles. Elle s’appuie sur l’expertise de son actionnariat privé pour
        assurer la réussite de ses projets. Pour chaque réalisation, la SEMPER est donc au cœur d’un
        partenariat avec une personne morale publique disposant de fonciers ou de toitures et l’actionnaire
        privé compétent dans le domaine. Elle se positionne en interlocuteur privilégié tout au long de la
        vie du projet (développement, construction et exploitation).
      </div>
    </div>


    <img id="background-img" src="../../assets/img/img6StThibery.webp" alt="photo panneaux photovoltaïques">
  </div>
</template>
<script>
export default {
  name: 'PrincipalSocietyView'
}
</script>

<style scoped>
@media screen and (min-width: 641px) {
  .imgAndTextContainer {
    display: grid;
    position: relative;

    margin-bottom: 10vh;
  }

  .imgAndTextContainer > * {
    grid-area: 1 / 1 / 2 / 2 ;
  }

  #background-img {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 74%;
    right: 0;
    top: 0;
    object-fit: cover;
  }

  .containerFirstPara {
    position: relative;
    z-index: 2;
    margin-top: 13vh;
    margin-bottom: 8.3vh;
    margin-left: 7.5%;
    opacity: 90%;
    background-color: white;
    width: 40%;
    min-width: 500px;
    text-align: left;
    padding: 3.6vh 2.4%;
  }

  .firstPara {
    font-size: 1.5625rem;
    line-height: 2.5625rem;
  }

  .smallTextFirstPara {
    margin-top: 17px;
    font-size: 1rem;
    line-height: 1.9375rem;
  }

  img {
    margin: 0;
    width: 700px;
  }
}

@media screen and (max-width: 640px) {
  .imgAndTextContainer {
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    margin: 0;
  }

  .containerFirstPara {
    position: relative;
    top: -70px;
    opacity: 90%;
    background-color: white;
    text-align: left;
    padding: 14px 18px 0 18px;
    margin: 0 19px -50px 19px;
    text-align: center;
  }

  .textContainer {
    padding: 0 10% 0 10%;
    margin: 0;
  }

  .firstPara {
    position: relative;
    top: -10%;
    font-size: 22px;
    line-height: 35px;
  }

  .smallTextFirstPara {
    margin-top: 20px;
    font-size: 16px;
    line-height: 31px;
  }


  img {
    width: 100%;
  }

}
</style>