<template>
  <div class="container" :class="{ 'theme-container': first }">
    <div :class="{ 'container-inner-1': !first, 'container-inner-first': first }">

      <div id="container-realisation">
        <span id="realisation-top-background" v-if="first">RÉALISATIONS</span>
      </div>

      <div class="container-inner">
        <realisation-txt style="position: relative; z-index: 10;" :first="first" :title="title" :subtitle="subtitle"
          :implementation="implementation" :power="power" :productible="productible" :surface="surface"
          :investment="investment" :production="production" :conso="conso" :partners="partners">
        </realisation-txt>

        <div id="container-right">

          <div id="container-header-img">
            <span class="nbTonnes" :class="{ 'theme-stroke': first }"> {{ tonnesCO2 }} tonnes </span><br />
            <span class="textBackgroundWhite">de rejets de CO2 évité</span>
          </div>

          <img v-on:click="routeTo" :class="{ 'last-img-height': last }" :src="require('../../assets/img/' + logo)"
            alt="Image de panneaux solaires" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RealisationTxt from './RealisationText.vue'

export default {
  name: "RealisationParaViewLeft",
  props: {
    index: Number,
    first: Boolean,
    last: Boolean,
    logo: String,
    title: String,
    subtitle: String,
    tonnesCO2: String,
    implementation: String,
    power: String,
    productible: String,
    surface: String,
    investment: String,
    production: String,
    conso: String,
    partners: String,
  },
  components: {
    RealisationTxt
  }, 
  methods: {
    routeTo() {
      this.$router.push({ path: '/carousel', query: { index: this.index } })
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
}

.container-inner {
  display: grid;

  position: relative;
  z-index: 2;
}

/* Coté droit */
#container-right {
  position: relative;
  height: inherit;
}


.textBackgroundWhite {
  background-color: #050734;
  color: white;
  font-size: 1.5rem;
  padding: 0 5px;
  padding: 0 4px;
}

/* Si le premier élément on prend ces classes */

#container-realisation {
  position: absolute;
  z-index: 2;
  top: 3vh;
  left: 0;
  max-width: 100%;
  overflow-x: hidden;
  height: -webkit-fill-available;
}

#realisation-top-background {
  color: #161842;
  font-size: 8rem;
}

.theme-container {
  background-color: #050734;
  padding-top: 7vh;
}

.theme-stroke {
  -webkit-text-stroke: 2px white;
}

/* Si c'est le dernier */
.last-img-height {
  height: 100%
}

img {
  cursor: pointer;
}

@media screen and (min-width: 640px) {
  .container-inner {
    grid-template-columns: auto 41%;
    column-gap: 13.2%;
    padding: 7vh 0 7vh 7.1vw;
    text-align: left;
  }

  #container-header-img {
    position: absolute;
    z-index: 2;
    left: -2.2rem;
  }

  img {
    position: absolute;
    z-index: 1;
    top: 3rem;
    right: 0;
    height: calc(100% + 16vh);
    width: 100%;
    object-fit: cover;
  }

}

@media screen and (max-width: 640px) {
  #realisation-top-background {
    line-height: 6rem;
  }

  .container-inner-1 {
    position: relative;
    top: -80px;
  }

  .container-inner-first {
    padding-bottom: 80px;
  }

  .container-inner {
    grid-template-rows: auto auto;
    padding-left: 35px;
    text-align: left;
  }

  #container-right {
    grid-row-start: 1;
    max-width: 100%;
    position: relative;
  }

  #container-header-img {
    position: absolute;
    top: 10px;
    left: 15px;
  }

  #container-right>img {
    height: 40.6vh;
    width: 100%;
    object-fit: cover;
  }

  .realisation-txt {
    margin-top: 25px;
    margin-bottom: 40px;
    grid-row-start: 2;
  }
}
</style>