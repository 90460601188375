<template>
  <div id="container-installations">
    <h2 class="uppercase">Les <span class="italicLight">installations </span> <span class="titrePara">
        développées</span></h2>
    <div class="pictoContainer">
      <div class="containerImgText">
        <img src="../../assets/img/pictoPanneau.webp" alt="logo Sictom" width="100px">
        <p>Centrales solaires au sol</p>
      </div>
      <div class="containerImgText">
        <img src="../../assets/img/pictoOmbriere.webp" alt="logo Sictom" width="100px">
        <p>Ombrières de parking</p>
      </div>
      <div class="containerImgText">
        <img src="../../assets/img/pictoToiture.webp" alt="logo Sictom" width="100px">
        <p>Centrales solaires en toiture</p>
      </div>
    </div>
    <router-link to="realisation#top-container-anchor">
      <button class="button">En savoir plus</button>
    </router-link>
    <a href="#top-container-anchor">
      <div id="arrow"></div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'InstallationsView'
}
</script>

<style scoped>
@media screen and (min-width: 641px) {
  #container-installations {
    padding-top: 8.5vh;
    padding-bottom: 8.5vh;
  }

  .pictoContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 5.8vh 10%;
  }

  p {
    font-size: 1.5625rem;
  }
}

@media screen and (max-width: 640px) {
  #container-installations {
    margin-top: 38px;
  }

  .pictoContainer {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 3% 10% 3% 10%;
  }

  img {
    width: 70%;
  }

  p {
    font-size: 10px;
  }

  .containerImgText {
    margin: auto;
    width: 40%;
  }

  .button {
    width: 50%;
    height: 2.5em;
    font-size: 15px;
  }

  #arrow {
    border-top: 4px solid #050734;
    border-left: 4px solid #050734;

    width: 16px;
    height: 16px;

    transform: rotate(45deg);
    transform-origin: top left;
    margin: 21px 27px 26px auto;
    margin-right: 27px;
  }
}</style>