<template>
  <div id="app">
    <TopView />
    <div class="containerSociety">
      <TopSociety />
      <div class="containerColumns">
        <div id="column-left" class="columns">
          <SocietyParaView :title="titleSictom" :para="paraSictom" :logo="logoSictom" />
          <SocietyParaView :title="titleTotal" :para="paraTotal" :logo="logoTotal" />
          <SocietyParaView :title="titleHerault" :para="paraHerault" :logo="logoHerault" />
        </div>
        <div id="column-right" class="columns">
          <SocietyParaView :title="titleAgglo" :para="paraAgglo" :logo="logoAgglo" />
          <SocietyParaView :title="titleBasaltenr" :para="paraBasaltenr" :logo="logoBasaltenr" />
          <SocietyParaView :title="titleQair" :para="paraQair" :logo="logoQair" />
          
          <a href="#app">
            <div class="fleche2"></div>
          </a>
          </div>

      </div>
      <FooterView />
    </div>
  </div>
</template>

<script>
import TopView from '../components/Top.vue'
import FooterView from '../components/Footer.vue'
import TopSociety from '../components/Society/TopSociety.vue'
import SocietyParaView from '../components/Society/SocietyPara.vue'

export default {
  name: 'SocietyPage',
  components: {
    TopView,
    FooterView,
    TopSociety,
    SocietyParaView
  },
  data() {
    return {
      logoSictom: 'logoSictom.webp',
      titleSictom: 'LE SICTOM DE PEZENAS AGDE',
      paraSictom: 'Le SICTOM Pézenas-Agde est compétent en matières de collecte et de traitement des déchets ménagers et assimilés sur son territoire (58 communes, 138 538 habitants). Le SICTOM a pour missions, la prévention, les collectes sélectives (verre, biodéchets, emballages ménagers recyclables), la collecte des ordures ménagères résiduelles, la gestion des déchèteries, le transfert, transport et traitement des déchets.<br><br>Depuis sa création en 1976, le SICTOM s’affirme comme <span class="bold">acteur de la Transition écologique</span>, proposant aux habitants une gestion de leurs déchets plus responsable et contribuant à la prise de conscience environnementale dès le plus jeune âge.',
      logoAgglo: 'logoAgglo.webp',
      titleAgglo: 'COMMUNAUTE d’AGGLOMERATION HERAULT MEDITERRANEE',
      paraAgglo: 'La communauté d’agglomération « Hérault Méditerranée » regroupe, sur 389,40 km2, 20 communes ce qui représente 81 737 habitants permanents et environ 350 000 habitants en saison.'
        + 'Elle recouvre un territoire cohérent, pertinent, et à taille humaine, qui allie un littoral de 20 km de long et un arrière-pays de toute beauté, avec, comme « colonne vertébrale » la basse vallée du fleuve Hérault depuis Saint Pons de Mauchiens jusqu’à l’embouchure à Agde. Il met en vitrine des traditions, un terroir, des paysages, des savoir faire de grande qualité et offre de nombreux atouts pour un développement maîtrisé et durable. Notre Communauté d’Agglomération repose sur un maillage équilibré des communes qui s’appuie sur les deux villes les plus importantes que sont Agde et Pézenas. Ce réseau permet de générer entre le littoral et le rétro-littoral des flux favorables au développement. Elle connaît aujourd’hui une croissance démographique particulièrement dynamique dans un environnement de qualité qu’il convient de préserver.',
      logoTotal: 'logoTotal.webp',
      titleTotal: 'TOTALENERGIES',
      paraTotal: 'TotalEnergies est une compagnie multi-énergies mondiale de production et de fourniture d’énergies : pétrole et biocarburants, gaz naturel et gaz verts, renouvelables et électricité. Ses 105 000 collaborateurs s’engagent pour'
        + ' une énergie toujours plus abordable, propre, fiable et accessible au plus grand nombre. Présent dans plus de 130 pays, TotalEnergies inscrit le développement durable dans toutes ses dimensions au cœur de ses projets et opérations pour contribuer au bien-être des populations.'
        + ' L’ambition de TotalEnergies est la neutralité carbone en 2050. TotalEnergies s’engage à exploiter, dans le monde, 100 GW d’électricité bas carbone en 2030.'
        + ' Nous portons l\'ambition de la compagnie en France pour le développement, la construction et l’exploitation des énergies renouvelables.',
      logoHerault: 'logoHerault.webp',
      titleHerault: 'HERAULT ENERGIES',
      paraHerault: "Créé en 1990 pour assurer le développement et le renforcement des réseaux électriques en Hérault, le syndicat Hérault Energies a depuis élargi son champ d’action. Hérault Energies est un établissement public de coopération intercommunale qui intervient dans trois domaines complémentaires : l’aménagement énergétique de l’Hérault (organisation du service public de l’électricité et du gaz, travaux sur les réseaux, sur l’éclairage public et les bornes de recharge des véhicules électriques), l’accompagnement à la maitrise des énergies (production d’énergie renouvelables, rénovation des bâtiments, aide à la rénovation), l’optimisation financière de l’énergie (achats groupés, CEE, etc.).Son action structure durablement le paysage énergétique héraultais tout en accompagnant le quotidien des communes et des intercommunalités, et donc des héraultais.",
      logoBasaltenr: 'logoBasaltenr.webp',
      titleBasaltenr: 'BASALT’ENR',
      paraBasaltenr: 'BASALT’ENR est une société spécialisée dans le développement de projets d’énergie renouvelable. Réunion de 2 sociétés, Incidences et Basaltis, elle dispose d’un savoir-faire reconnu lui permettant d’identifier des projets de centrales photovoltaïques, de mener à bien leur développement, leur financement, leur construction et leur exploitation.',
      logoQair: 'qair.webp',
      titleQair: 'QAIR',
      paraQair: `Qair est un Producteur Indépendant d’Electricité (IPP), qui exploite et construit environ 1 GW d’actifs de production d’énergie électrique exclusivement à partir de sources renouvelables (fin 2022). Le groupe est en phase de finalisation (construction et financement) pour 600 MW supplémentaires et développe par ailleurs plus de 25 GW d’actifs pour des déploiements futurs, au cœur des 20 territoires et pays sur lesquels il est présent. Depuis plus de 30 ans, l’entreprise s’appuie sur le savoir-faire historique de ses experts et sur la vision de son actionnaire de référence Jean-Marc Bouchet. Cette longue expérience repose sur le développement et l’exploitation de plus de 600 MW de capacités renouvelables en France, représentant plus d’un milliard d’euros d’investissement. Le Groupe Qair développe des projets à partir des énergies renouvelables sur terre, sur mer (éolien flottant et posé), dans l’eau (hydrolien), à partir de déchets ménagers et produit par électrolyse de l’eau de l’hydrogène vert. Grâce à son réseau de proximité international en Europe, Amérique du Sud et Afrique, Qair poursuit un développement global, raisonné et « multi-local ». Cette approche souple et territoriale lui permet d’envisager une croissance rapide pour les prochaines années.`
    }
  }
}
</script>

<style>
@media screen and (min-width: 641px) {
  .containerSociety {
    background-color: #DEE9EF;
  }

  .containerColumns {
    background-color: #DEE9EF;
    display: flex;
    justify-content: space-between;
    padding: 0 8%;
  }

  .columns {
    width: 38vw;
  }

  .columns>* {
    margin-bottom: 5.3vh;
  }

  #column-left {
    margin-right: 3.2%;
  }

  #column-right {
    margin-left: 3.2%;
  }

  .fleche2 {
    margin-right: 0;
    margin-left: auto;
    transform-origin: bottom right;
    transform: rotate(-45deg);
    border-width: 0.20rem 0.20rem 0 0;
    width: 1.5rem;
  height: 1.5rem;
    cursor: pointer;
    margin-bottom: 8vh;
  }
}

@media screen and (max-width: 640px) {
  .containerSociety {
    background-color: white;
  }

  .containerColumns {
    background-color: white;
  }
}
</style>
