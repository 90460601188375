<template>
  <div class="container" id="top-container-anchor">
    <img @click="routeTo('/')" src="../assets/img/logoSemperCouleur.webp" alt="logo Semper">
    <nav class="navigation uppercase">
      <ul>
        <li><router-link to="/society">Société</router-link></li>
        <li id="separator">|</li>
        <li><router-link to="/realisation" class="nav-link">Réalisations</router-link></li>
      </ul>
    </nav>
  </div>
</template>

<script>

export default {
  name: 'TopView',
  methods: {
    routeTo(route) {
      this.$router.push(route);
    },
  },
  props: {
    pageActivated: String
  }
}

</script>

<style scoped>
.container>img {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #02093B;
  font-size: 1.7rem;
}

.router-link-exact-active {
  text-decoration: underline;
  text-underline-offset: 5px;
}

@media screen and (min-width:641px) {
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 14.3vh;
    padding: 0 6% 0 7%;
  }

  .container>img {
    height: 3.7rem;
    object-fit: contain;
  }

  ul {
    list-style-type: none;
  }

  #separator {
    margin: 0 7vw;
  }

  li {
    display: inline-block;

  }

  a:hover {
    color: red;
  }
}


@media  (max-width:1280px) and (min-width: 640px) {
  a {
    font-size: 2.5rem;
  }
  .container>img {
    height: 4rem;
    
  }
}


@media screen and (max-width:640px) {
  .container {
    display: grid;
    grid-template-rows: repeat(2, auto);
  }

  .container>img {
    margin: auto;
    padding: 37px 0 25px 0;
    max-width: 80%;
    height: 35px;
    object-fit: contain;
  }

  .navigation {
    margin-bottom: 3.7vh;
    text-align: center;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 0 0 5%;
    font-size: 20px;
  }

  a {
    font-size: 1.8rem;
  }

  a:hover {
    color: red;
    font-size: 1.8rem;
  }
}
</style>

